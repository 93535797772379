/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { PropsWithChildren } from 'react';
import { InactivityModalPresenterContextProvider } from '@hkm/features/app/modals/inactivity/presenter/Provider';

import { createProvider, ProviderComposer } from '@ac/react-infrastructure';

const providers = [
  createProvider({ component: InactivityModalPresenterContextProvider }),
];

export const AppModalsPresenterProviders = ({
  children,
}: PropsWithChildren): JSX.Element => {
  return <ProviderComposer providers={providers}>{children}</ProviderComposer>;
};
