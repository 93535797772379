import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PostingFooter from '@hkm/components/Posting/PostingView/Body/PostingFooter/PostingFooter';
import PostingSummary from '@hkm/components/Posting/PostingView/Body/PostingSummary/PostingSummary';
import PostingViewReservationTile from '@hkm/components/Posting/PostingView/Body/PostingViewReservationTile/PostingViewReservationTile';
import TransactionCodeItem from '@hkm/components/Posting/PostingView/Body/TransactionCodeItem/TransactionCodeItem';
import { selectPostingReservationChargedState } from '@hkm/components/Posting/PostingView/domain/selectors';
import { useReservationChargedItems } from '@hkm/components/Posting/PostingView/hooks/useReservationChargedItems';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import { ReservationChargesData } from '@hkm/shared/domain/reservationCharges/models/reservationChargesData';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { UnifiedRoomDetails } from '@hkm/types/room/model/UnifiedRoomDetails';

import { TransactionCodeReadModel } from '@ac/library-api';
import { AcFieldSearch } from '@ac/mobile-components/dist/components/field';
import {
  AcTile,
  AcTileVariant,
} from '@ac/mobile-components/dist/components/tile';
import { Form, FormApi, formFieldFactory } from '@ac/react-infrastructure';
import { DividerSpacing, IconName } from '@ac/web-components';

export interface PostingDetailsBodyProps {
  reservation: UnifiedReservationDetails;
  unifiedRoomDetails: UnifiedRoomDetails;
  transactionCodesMap: Map<string, TransactionCodeReadModel>;
  onCloseHandler(): void;
  onSubmitHandler(data: ReservationChargesData): void;
}

export interface TransactionFormData {
  [key: string]: number;
}

const FormField = formFieldFactory<TransactionFormData>();

const PostingDetailsBody: FC<PostingDetailsBodyProps> = (
  props: PostingDetailsBodyProps
) => {
  const { t, i18n } = useTranslation();
  const prefix = 'posting-details-view';
  const [searchItem, setSearchItem] = useState<string>('');

  const reservationChargedItemsMap = useReservationChargedItems();
  const reservationChargedState = useSelector(
    selectPostingReservationChargedState
  );

  const filteredTransactionCodesIds: string[] = useMemo(() => {
    return Array.from(props.transactionCodesMap.values())
      .filter((transactionCode: TransactionCodeReadModel) => {
        const subCodeDescription =
          transactionCode.getSubCodeDescription(i18n.language) ||
          transactionCode.getSubCodeDescription(
            APP_CONSTANTS.FALLBACK_LANGUAGE
          );

        return subCodeDescription
          ?.toLowerCase()
          .includes(searchItem.toLowerCase());
      })
      .map((transactionCode: TransactionCodeReadModel) => transactionCode.id);
  }, [searchItem, props.transactionCodesMap, i18n.language]);

  const initialValues: TransactionFormData = useMemo(
    () => ({
      ...Array.from(props.transactionCodesMap.values()).reduce(
        (
          transactionFormData: TransactionFormData,
          transactionCode: TransactionCodeReadModel
        ) => ({
          ...transactionFormData,
          [transactionCode.id]: 0,
        }),
        {}
      ),
    }),
    [props.transactionCodesMap]
  );

  const onSubmit = useCallback(
    (formData: TransactionFormData, form: FormApi<TransactionFormData>) => {
      props.onSubmitHandler({
        version: reservationChargedState.data?.version ?? 0,
        reservationId: props.reservation.id,
        data: Object.entries(formData)
          .filter(([, value]) => value > 0)
          .map(([key, value]) => ({
            quantity: value,
            transactionCodeId: key,
          })),
      });
      setTimeout(() => {
        form.reset(initialValues);
      }, 0);
    },
    [reservationChargedState.data, initialValues, props]
  );

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {(formRenderProps) => (
        <>
          <PostingViewReservationTile
            reservation={props.reservation}
            unifiedRoomDetails={props.unifiedRoomDetails}
          />

          <AcTile
            variant={AcTileVariant.Transparent}
            title={t('POSTING.POSTING_DETAILS.ITEM_TO_POST')}
            icon={IconName.food}
          >
            <AcFieldSearch
              value={searchItem}
              onChange={setSearchItem}
              placeholder={t('POSTING.POSTING_DETAILS.SEARCH_PLACEHOLDER')}
            />
          </AcTile>

          {Object.keys(formRenderProps.values).map((key) => {
            return filteredTransactionCodesIds.includes(key) ? (
              <FormField valuePath={key} key={key}>
                {(fieldRenderProps) => (
                  <TransactionCodeItem
                    fieldProps={fieldRenderProps}
                    charged={reservationChargedItemsMap.get(key)}
                    transactionCode={props.transactionCodesMap.get(key)}
                  />
                )}
              </FormField>
            ) : null;
          })}

          <ac-box class="ac-spacing-horizontal-md">
            <ac-divider spacing={DividerSpacing.md} />
          </ac-box>

          <PostingSummary
            formProps={formRenderProps}
            transactionCodesMap={props.transactionCodesMap}
          />

          <PostingFooter
            testSelector={prefix}
            formProps={formRenderProps}
            onCloseHandler={props.onCloseHandler}
            onSubmitHandler={props.onSubmitHandler}
          />
        </>
      )}
    </Form>
  );
};

export default memo(PostingDetailsBody);
