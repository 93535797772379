/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { InactivityModal } from '@hkm/features/app/modals/inactivity/Modal';

export const AppModals = (): JSX.Element => {
  return (
    <>
      <InactivityModal />
    </>
  );
};
